var site = window.site || {};
var generic = window.generic || {};

(function ($) {
  Drupal.behaviors.contentBlockLoyaltySignupPopupV1 = {
    attach: function (context) {
      var $loyaltyJoin = $('.js-loyalty-join', context);
      var $loyaltyPopup = $('.js-loyalty-popup', context);
      var $loyaltyForm = $('.js-loyalty-elist-form', context);
      var $loyaltyCloseIcon = $('.js-loyalty-close', context);
      var $emailRegisterField = $('#form--registration_short--field--PC_EMAIL_ADDRESS', context);
      var $emailError = $('.js-loyalty-error', context);
      var $emailValidationError = $('.js-loyalty-validation-error', context);
      var $signinField = $('.js-email-popup-signup', context);
      var emailValue = '';
      var registeredEmailValue = '';
      var storeRegisterValue = '';
      var storeSigninValue = '';
      var hideBanner = '';
      var $body = $('body');
      var emailRegValidate = /^([A-Za-z0-9_\-.!#$^&*+=.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
      var successUrl = $('.js-loyalty-signup-success', context).attr('href');
      var $emailSigninField = $('#form--signin--field--EMAIL_ADDRESS', context);
      var hideLoyaltyBanner = function () {
        hideBanner = localStorage.getItem('hideSignupBanner');

        if (hideBanner) {
          $loyaltyPopup.addClass('hidden');
          $body.removeClass('loyalty-popup--enabled');
        }
      };

      $body.addClass('loyalty-popup--enabled');

      $loyaltyCloseIcon.once().on('click', function () {
        localStorage.setItem('hideSignupBanner', 'true');
        hideLoyaltyBanner();
      });

      $loyaltyForm.once().on('submit', function (event) {
        event.preventDefault();
        emailValue = $(this).find($signinField).val();

        if ($.trim(emailValue).length === 0) {
          $emailError.removeClass('hidden');
          $emailValidationError.addClass('hidden');
        } else if (!emailRegValidate.test(emailValue)) {
          $emailValidationError.removeClass('hidden');
          $emailError.addClass('hidden');
        } else {
          $emailError.addClass('hidden');
          $emailValidationError.addClass('hidden');
          $loyaltyCloseIcon.trigger('click');
          generic.jsonrpc.fetch({
            method: 'user.registered',
            params: [{ email_address: emailValue }],
            onSuccess: function (reg) {
              $(document).trigger('loyalSignup');
              registeredEmailValue = reg.getValue();
              if (registeredEmailValue) {
                localStorage.setItem('signinEmail', emailValue);
                localStorage.removeItem('registerEmail');
              } else {
                localStorage.setItem('registerEmail', emailValue);
                localStorage.removeItem('signinEmail');
              }
              window.location.href = successUrl;
            }
          });
        }
      });

      storeRegisterValue = localStorage.getItem('registerEmail');
      storeSigninValue = localStorage.getItem('signinEmail');

      if ($emailSigninField.val() === '') {
        $emailSigninField.val(storeSigninValue);
      }

      if ($emailRegisterField.val() === '') {
        $emailRegisterField.val(storeRegisterValue);
      }

      hideLoyaltyBanner();
      $loyaltyPopup.addClass('active');

      $loyaltyJoin
        .on('mouseover', function () {
          $loyaltyPopup.removeClass('hidden active');
        })
        .on('mouseleave', function () {
          $loyaltyPopup.addClass('hidden');
        });
    }
  };
})(jQuery);
